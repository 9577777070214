import React from 'react'

export const Declaration = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1 23.76H20.9M10.1 18.32H20.9M20.9003 4.72H27.65C28.008 4.72 28.3514 4.86329 28.6046 5.11834C28.8578 5.37338 29 5.71931 29 6.08V34.64C29 35.0007 28.8578 35.3466 28.6046 35.6017C28.3514 35.8567 28.008 36 27.65 36H3.35C2.99196 36 2.64858 35.8567 2.39541 35.6017C2.14223 35.3466 2 35.0007 2 34.64V6.08C2 5.71931 2.14223 5.37338 2.39541 5.11834C2.64858 4.86329 2.99196 4.72 3.35 4.72H10.0996M8.75 10.16V8.8C8.75 6.99653 9.46116 5.26692 10.727 3.99167C11.9929 2.71643 13.7098 2 15.5 2C17.2902 2 19.0071 2.71643 20.273 3.99167C21.5388 5.26692 22.25 6.99653 22.25 8.8V10.16H8.75Z"
      stroke="#2647D7"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
