export const House = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Casa</title>
    <desc>Ilustração simplificada de uma casa.</desc>
    <path
      d="M40.5 40.5V21.6636C40.5 21.4546 40.4563 21.2479 40.3718 21.0568C40.2872 20.8656 40.1637 20.6943 40.009 20.5537L25.008 6.91587C24.7319 6.66484 24.3721 6.52574 23.9989 6.52576C23.6257 6.52577 23.266 6.66488 22.9899 6.91593L7.9909 20.5537C7.83628 20.6943 7.71274 20.8656 7.6282 21.0568C7.54367 21.2479 7.5 21.4545 7.5 21.6635V40.5"
      stroke="#00198A"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3 40.5H45" stroke="#00198A" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M28.499 40.4985V28.4985H19.499V40.4985"
      stroke="#00198A"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
