import React from 'react'

export const Pencil = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.25 2.5C25.1837 2.5 25.1201 2.52634 25.0732 2.57322L2.57322 25.0732C2.55001 25.0964 2.53159 25.124 2.51903 25.1543C2.50647 25.1847 2.5 25.2172 2.5 25.25V33.6287C2.5 33.695 2.52634 33.7586 2.57322 33.8055C2.62011 33.8523 2.68369 33.8787 2.75 33.8787H11.2322L33.8055 11.3055C33.8523 11.2586 33.8787 11.195 33.8787 11.1287C33.8787 11.0624 33.8523 10.9988 33.8055 10.9519L25.4268 2.57322C25.3799 2.52634 25.3163 2.5 25.25 2.5ZM23.3055 0.805455C23.8212 0.28973 24.5207 0 25.25 0C25.9793 0 26.6788 0.28973 27.1945 0.805455L35.5732 9.18414C36.0889 9.69986 36.3787 10.3993 36.3787 11.1287C36.3787 11.858 36.0889 12.5575 35.5732 13.0732L12.6339 36.0126C12.3995 36.247 12.0815 36.3787 11.75 36.3787H2.75C2.02066 36.3787 1.32118 36.089 0.805457 35.5732C0.289731 35.0575 0 34.358 0 33.6287V25.25C-1.19209e-07 24.8889 0.0711306 24.5313 0.209331 24.1976C0.347531 23.864 0.550095 23.5608 0.805457 23.3055L23.3055 0.805455Z"
      fill="#2647D7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3661 5.74502C18.8543 5.25687 19.6457 5.25687 20.1339 5.74502L30.6339 16.245C31.122 16.7332 31.122 17.5246 30.6339 18.0128C30.1457 18.5009 29.3543 18.5009 28.8661 18.0128L18.3661 7.51279C17.878 7.02463 17.878 6.23318 18.3661 5.74502Z"
      fill="#2647D7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.461576 23.8402C0.949731 23.3521 1.74119 23.3521 2.22934 23.8402L12.2678 33.8787H34.25C34.9404 33.8787 35.5 34.4383 35.5 35.1287C35.5 35.819 34.9404 36.3787 34.25 36.3787H11.75C11.4185 36.3787 11.1006 36.247 10.8661 36.0126L0.461575 25.608C-0.0265799 25.1199 -0.0265799 24.3284 0.461576 23.8402Z"
      fill="#2647D7"
    />
  </svg>
)
