import React from 'react'

export const Partnership = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.6366 2.61845C40.6265 2.61926 40.6133 2.62229 40.5971 2.63095L37.0741 4.51858L41.8644 14.3491L45.252 12.534C45.2687 12.5251 45.2899 12.5093 45.311 12.4829C45.3323 12.4564 45.3522 12.4207 45.3656 12.3764C45.379 12.3321 45.3845 12.2834 45.3806 12.2341C45.3767 12.1849 45.3639 12.1399 45.3455 12.1022L40.7819 2.73702C40.7452 2.66161 40.6965 2.63207 40.671 2.62322C40.6578 2.61865 40.6466 2.61764 40.6366 2.61845ZM39.3606 0.32315C40.0281 -0.0344692 40.8101 -0.0996533 41.5287 0.149534C42.2438 0.39749 42.812 0.926136 43.1355 1.59012L47.6991 10.9553C47.8632 11.2921 47.9615 11.6569 47.9907 12.0287C48.02 12.4005 47.98 12.7756 47.872 13.1333C47.764 13.491 47.589 13.8274 47.3533 14.1212C47.1174 14.4153 46.8245 14.6618 46.4885 14.8418L41.9018 17.2994C41.5881 17.4675 41.2194 17.5006 40.8808 17.3911C40.5421 17.2816 40.2626 17.0389 40.1067 16.7189L34.1686 4.53306C33.8605 3.90072 34.1072 3.13791 34.7272 2.80571L39.3606 0.32315Z"
      fill="#00198A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.36341 2.61845C7.3534 2.61764 7.34221 2.61865 7.32905 2.62322C7.30353 2.63207 7.25484 2.66162 7.2181 2.73702L2.6545 12.1022C2.63612 12.1399 2.62327 12.1849 2.6194 12.2341C2.61552 12.2834 2.62101 12.3321 2.6344 12.3764C2.64776 12.4207 2.66769 12.4564 2.68899 12.4829C2.71015 12.5093 2.73129 12.5251 2.74797 12.534L6.13557 14.3491L10.9259 4.51857L7.40286 2.63095C7.3867 2.62229 7.37347 2.61926 7.36341 2.61845ZM8.63936 0.323146L13.2728 2.80571C13.8928 3.13791 14.1395 3.90072 13.8314 4.53306L7.89326 16.7189C7.73735 17.0389 7.45788 17.2815 7.11923 17.3911C6.78057 17.5006 6.41193 17.4675 6.09821 17.2994L1.51147 14.8418C1.17547 14.6618 0.882603 14.4153 0.646728 14.1212C0.411 13.8274 0.23603 13.491 0.128001 13.1333C0.0199918 12.7756 -0.0199725 12.4005 0.0092864 12.0287C0.0385466 11.6569 0.136764 11.2921 0.300887 10.9553L4.86448 1.59013C5.18803 0.92615 5.7562 0.397497 6.47127 0.149538C7.18989 -0.0996523 7.97189 -0.0344726 8.63936 0.323146Z"
      fill="#00198A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50271 15.354C5.93981 14.7781 6.76101 14.6656 7.3369 15.1027L18.1788 23.3318C18.2091 23.3547 18.2463 23.373 18.2886 23.3832L29.7842 26.1728C29.8367 26.1855 29.8922 26.1848 29.944 26.1708C29.9958 26.1569 30.0389 26.1313 30.0706 26.1005L37.3324 19.0518L40.4721 15.4866C40.9499 14.944 41.7771 14.8916 42.3197 15.3694C42.8623 15.8472 42.9148 16.6744 42.437 17.217L39.2634 20.8207C39.2408 20.8463 39.2173 20.871 39.1928 20.8948L31.8942 27.9792C31.5384 28.3245 31.1002 28.5712 30.6238 28.6992C30.1476 28.8273 29.646 28.8334 29.1668 28.7171L17.6712 25.9276C17.2823 25.8332 16.9158 25.66 16.5959 25.4172L5.754 17.1882C5.17811 16.7511 5.0656 15.9299 5.50271 15.354Z"
      fill="#00198A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1312 4.36365C26.1131 4.36365 26.0928 4.36733 26.0706 4.37721C26.048 4.38722 26.0214 4.40462 25.9947 4.43316L18.4285 12.5447C18.3992 12.5761 18.3714 12.6196 18.3521 12.6748C18.3329 12.7302 18.3241 12.7921 18.3283 12.8552C18.3325 12.9183 18.3491 12.9761 18.3738 13.0246C18.3983 13.0728 18.4285 13.1076 18.4572 13.1306L19.5052 13.9733C20.3503 14.6528 21.3652 15.0109 22.397 15.0109C23.4289 15.0109 24.4438 14.6528 25.2889 13.9733L27.7636 11.9834C28.237 11.6029 28.9099 11.5978 29.3889 11.9713L37.8958 18.604C38.466 19.0485 38.5678 19.8711 38.1233 20.4413C37.6787 21.0115 36.8561 21.1133 36.286 20.6688L28.5964 14.6734L26.9295 16.0137C25.6327 17.0563 24.0425 17.6291 22.397 17.6291C20.7516 17.6291 19.1614 17.0563 17.8646 16.0137L16.8166 15.171C16.4906 14.9089 16.2276 14.5802 16.04 14.2113C15.8525 13.8426 15.7431 13.4393 15.7158 13.0284C15.6886 12.6175 15.7438 12.2038 15.8795 11.814C16.0153 11.4241 16.2298 11.0634 16.5139 10.7588L24.0802 2.64729C24.3408 2.36789 24.6552 2.141 25.008 1.98435C25.361 1.82757 25.743 1.74547 26.1312 1.74547H33.9975C34.7205 1.74547 35.3066 2.33157 35.3066 3.05456C35.3066 3.77755 34.7205 4.36365 33.9975 4.36365H26.1312Z"
      fill="#00198A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7455 2.65771C23.569 2.61519 23.3479 2.60502 23.143 2.63683L12.8553 4.23376C12.1409 4.34466 11.4718 3.8554 11.3609 3.14096C11.25 2.42653 11.7393 1.75746 12.4537 1.64656L22.7414 0.0496313C23.2715 -0.0326484 23.8378 -0.0131639 24.3588 0.112361L31.2884 1.78188C31.9913 1.95122 32.4238 2.6583 32.2545 3.36118C32.0852 4.06406 31.3781 4.49657 30.6752 4.32723L23.7455 2.65771Z"
      fill="#00198A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0167 24.7877C10.5457 24.2948 11.374 24.3241 11.8669 24.8531L15.733 29.0025C15.7379 29.0077 15.7418 29.0114 15.7446 29.0138L20.8952 30.6037C21.586 30.817 21.9732 31.5499 21.7599 32.2407C21.5467 32.9315 20.8138 33.3187 20.123 33.1054L14.9562 31.5105C14.5084 31.3723 14.122 31.1142 13.8175 30.7873L9.95131 26.6379C9.45845 26.1089 9.48773 25.2805 10.0167 24.7877Z"
      fill="#00198A"
    />
  </svg>
)
