import React from 'react'

export const PaymentIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Cifrão</title>
    <desc>Símbolo cifrão</desc>
    <path
      d="M41.2987 29.5248C40.0854 29.9959 37.6138 31.4092 36.5802 32.1631C35.5467 32.9169 33.839 33.4351 31.637 33.8591C31.1427 33.9533 30.5585 34.0004 29.9294 34.0476C28.4015 34.0947 26.604 33.9062 24.6267 33.7649C24.4919 33.7649 24.3571 33.7178 24.2223 33.7178C23.7279 33.6707 23.3235 33.2938 23.2786 32.7755C23.2336 32.116 23.7279 31.5977 24.3121 31.6919C24.4469 31.6919 24.6267 31.7391 24.7615 31.7391C27.323 31.9746 29.7496 32.2102 31.2775 31.8804C34.1086 31.315 34.9175 29.5248 34.7378 28.0172C34.603 26.7923 33.7042 25.6144 32.3111 25.7087C30.3338 25.85 27.9971 25.7087 25.7052 25.5202C23.0988 25.3318 20.4475 25.2375 18.5601 25.4731C16.448 25.7087 12.1788 27.3576 8.40405 30.043C6.56158 31.3622 4.49443 32.9169 3.23617 33.9062C2.60703 34.3773 2.47222 35.3196 2.96654 35.9792L8.44898 43.4229C8.89837 44.0825 9.79713 44.1767 10.4263 43.7056L12.4485 42.0567C12.9428 41.6798 13.5719 41.5384 14.1112 41.7269C17.7961 42.9518 21.7507 43.7527 24.3121 43.894C29.9294 44.1767 40.7595 37.2041 43.8602 34.9427C46.9609 32.7284 44.714 28.1585 41.2987 29.5248Z"
      stroke="#00198A"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3332 20C32.1196 20 35.9998 16.1198 35.9998 11.3333C35.9998 6.54683 32.1196 2.66663 27.3332 2.66663C22.5467 2.66663 18.6665 6.54683 18.6665 11.3333C18.6665 16.1198 22.5467 20 27.3332 20ZM26.4889 6.71308V5.54235C26.4889 5.41692 26.5784 5.33329 26.7127 5.33329H27.921C28.0552 5.33329 28.1447 5.41692 28.1447 5.54235V6.67127C29.3978 6.7549 30.4272 7.63295 30.5167 8.80368C30.5167 8.92911 30.4272 9.05455 30.2929 9.05455H29.0398C28.9503 9.05455 28.816 8.97092 28.816 8.8873C28.7265 8.51099 28.4133 8.26012 27.9657 8.26012H26.8917C26.2651 8.26012 25.6833 8.67824 25.5938 9.26361C25.5043 9.9326 26.0861 10.518 26.8022 10.518H27.742C29.4426 10.518 30.8299 11.8978 30.6509 13.5284C30.5167 14.7828 29.4426 15.7444 28.1 15.9535V17.1242C28.1 17.2497 28.0105 17.3333 27.8762 17.3333H26.7574C26.6231 17.3333 26.5336 17.2497 26.5336 17.1242V15.9953C25.2806 15.9117 24.2512 15.0336 24.1617 13.8629C24.1617 13.7375 24.2512 13.612 24.3855 13.612H25.6386C25.7281 13.612 25.8623 13.6957 25.8623 13.7793C25.9518 14.1556 26.2651 14.4065 26.7127 14.4065H27.7867C28.4133 14.4065 28.9951 13.9883 29.0846 13.3612C29.1741 12.6922 28.5923 12.1068 27.8762 12.1068H27.0707C25.5491 12.1068 24.1617 11.0615 24.0275 9.63991C23.8037 8.1765 24.9673 6.88033 26.4889 6.71308Z"
      fill="#00198A"
    />
  </svg>
)
