export const Commerce = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Dívidas</title>
    <desc>Ilustração simplificada de uma mão segurando uma moeda.</desc>
    <path d="M24 4.5V9" stroke="#00198A" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24 39V43.5" stroke="#00198A" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M34.5 16.5C34.5 15.5151 34.306 14.5398 33.9291 13.6299C33.5522 12.7199 32.9997 11.8931 32.3033 11.1967C31.6069 10.5003 30.7801 9.94781 29.8701 9.5709C28.9602 9.19399 27.9849 9 27 9H20.25C18.2609 9 16.3532 9.79018 14.9467 11.1967C13.5402 12.6032 12.75 14.5109 12.75 16.5C12.75 18.4891 13.5402 20.3968 14.9467 21.8033C16.3532 23.2098 18.2609 24 20.25 24H28.5C30.4891 24 32.3968 24.7902 33.8033 26.1967C35.2098 27.6032 36 29.5109 36 31.5C36 33.4891 35.2098 35.3968 33.8033 36.8033C32.3968 38.2098 30.4891 39 28.5 39H19.5C17.5109 39 15.6032 38.2098 14.1967 36.8033C12.7902 35.3968 12 33.4891 12 31.5"
      stroke="#00198A"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
