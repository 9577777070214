export const Fire: React.FC = (props) => (
  <svg width={44} height={44} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Ilustração simplificada de uma chama.</title>
    <path
      d="M17.875 19.25l-5.916-4.558C9.85 18.002 8.25 21.903 8.25 26.125a13.75 13.75 0 0027.5 0c0-12.375-12.375-22-12.375-22l-5.5 15.125z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
