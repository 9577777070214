import React from 'react'

export const Logo: React.FC = () => (
  <svg width="42" height="40" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Provi</title>
    <desc>Logo da Provi</desc>
    <path
      d="M36.8667 7.23838C28.7892 -2.03493 14.7027 -2.398 6.15222 6.14917C2.01854 10.2828 -0.0299789 15.7123 0.00333037 21.1317L0 21.1283V41.9966H20.8683L20.8649 41.9933C26.6874 42.0299 32.5232 39.665 36.7501 34.8884C43.6751 27.0641 43.7284 15.1127 36.8667 7.23838ZM26.6341 26.631C23.523 29.7421 18.48 29.7421 15.3689 26.631C12.2578 23.52 12.2578 18.4769 15.3689 15.3658C18.48 12.2548 23.523 12.2548 26.6341 15.3658C29.7452 18.4769 29.7452 23.52 26.6341 26.631Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="41.9791" y1="20.9976" x2="0.00316248" y2="20.9976" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2369F1" />
        <stop offset="0.999" stopColor="#06C1EF" />
      </linearGradient>
    </defs>
  </svg>
)
