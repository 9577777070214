export const GameController: React.FC = (props) => (
  <svg width={44} height={44} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Ilustração simplificada de um controle de vídeo game.</title>
    <path
      d="M26.125 18.563h5.5M12.375 18.563h5.5M15.125 15.813v5.5M29.565 9.58l-15.128.045a8.94 8.94 0 00-8.803 7.385h.002L2.823 31.477a4.813 4.813 0 008.143 4.239h0l7.43-8.215 11.169-.045a8.938 8.938 0 100-17.875v0z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.367 16.966l2.81 14.51a4.813 4.813 0 01-8.142 4.239h0L25.61 27.47"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
