interface IArrowRight {
  color?: string
}

export const ArrowRight = ({ color = 'black' }: IArrowRight) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Ícone seta esquerda</title>
    <desc>Ilustração de uma seta apontando para a esquerda.</desc>
    <path
      d="M21 12L3.75 12M10 19L3 12L10 4.5"
      stroke={color && color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
