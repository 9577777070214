import React from 'react'

export const Organization = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5054 32.75L15.5039 21.5015L4.2554 21.5M2 35.0053L15.5 21.5053M24.5054 30.5H33.5054C33.9032 30.5 34.2848 30.342 34.5661 30.0607C34.8474 29.7794 35.0054 29.3978 35.0054 29V3.5C35.0054 3.10218 34.8474 2.72064 34.5661 2.43934C34.2848 2.15804 33.9032 2 33.5054 2H8.0054C7.60758 2 7.22605 2.15804 6.94474 2.43934C6.66344 2.72064 6.5054 3.10218 6.5054 3.5V12.5"
      stroke="#2647D7"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
