import React from 'react'

export const ArrowDown = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3432 19.4289C12.9527 19.0384 12.3195 19.0384 11.929 19.4289C11.5384 19.8195 11.5384 20.4526 11.929 20.8432L13.3432 19.4289ZM19 26.5L18.2929 27.2071C18.6834 27.5976 19.3166 27.5976 19.7071 27.2071L19 26.5ZM26.0711 20.8432C26.4616 20.4526 26.4616 19.8195 26.0711 19.4289C25.6805 19.0384 25.0474 19.0384 24.6568 19.4289L26.0711 20.8432ZM20 11.5C20 10.9477 19.5523 10.5 19 10.5C18.4477 10.5 18 10.9477 18 11.5L20 11.5ZM36 19C36 28.3888 28.3888 36 19 36V38C29.4934 38 38 29.4934 38 19H36ZM19 36C9.61116 36 2 28.3888 2 19H0C0 29.4934 8.50659 38 19 38V36ZM2 19C2 9.61116 9.61116 2 19 2V0C8.50659 0 0 8.50659 0 19H2ZM19 2C28.3888 2 36 9.61116 36 19H38C38 8.50659 29.4934 0 19 0V2ZM11.929 20.8432L18.2929 27.2071L19.7071 25.7929L13.3432 19.4289L11.929 20.8432ZM19.7071 27.2071L26.0711 20.8432L24.6568 19.4289L18.2929 25.7929L19.7071 27.2071ZM18 11.5L18 26.5L20 26.5L20 11.5L18 11.5Z"
      fill="#2647D7"
    />
  </svg>
)
