interface IArrowLeft {
  color?: string
}

export const ArrowLeft = ({ color = 'black' }: IArrowLeft) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Ícone seta direita</title>
    <desc>Ilustração de uma seta apontando para a direita.</desc>
    <path
      d="M1 8L18.25 8M12 1L19 8L12 15.5"
      stroke={color && color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
