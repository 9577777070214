import React from 'react'

export const LogoFullBlue: React.FC = () => (
  <svg width="142" height="40" viewBox="0 0 82 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Provi</title>
    <desc>Logo da Provi</desc>
    <path
      d="M21.4542 4.21229C16.7536 -1.1842 8.55608 -1.39549 3.58022 3.57844C1.17467 5.98399 -0.0174459 9.14358 0.00193807 12.2973L0 12.2954V24.4395H12.1441L12.1421 24.4375C15.5304 24.4589 18.9265 23.0826 21.3863 20.3029C25.4163 15.7496 25.4473 8.79466 21.4542 4.21229ZM15.4994 15.4976C13.689 17.3081 10.7542 17.3081 8.94376 15.4976C7.1333 13.6872 7.1333 10.7524 8.94376 8.94198C10.7542 7.13152 13.689 7.13152 15.4994 8.94198C17.3099 10.7524 17.3099 13.6872 15.4994 15.4976Z"
      fill="#2647D7"
    />
    <path
      d="M39.6217 13.4041C39.6217 17.058 36.9738 19.8784 33.7619 19.8784C32.1181 19.8784 30.9163 19.3143 30.108 18.3819V24.4394H26.429V7.27492H30.108V8.42826C30.9163 7.49589 32.1181 6.93182 33.7619 6.93182C36.9738 6.92988 39.6217 9.75025 39.6217 13.4041ZM35.9426 13.4041C35.9426 11.5646 34.7156 10.4132 33.0253 10.4132C31.3331 10.4132 30.108 11.5665 30.108 13.4041C30.108 15.2437 31.335 16.3951 33.0253 16.3951C34.7175 16.3951 35.9426 15.2417 35.9426 13.4041Z"
      fill="#02163D"
    />
    <path
      d="M49.0617 7.02874V11.1963C47.542 10.9521 45.3845 11.5646 45.3845 13.9915V19.5333H41.7055V7.27298H45.3845V9.45561C45.873 7.81185 47.5168 7.02874 49.0617 7.02874Z"
      fill="#02163D"
    />
    <path
      d="M49.7963 13.4041C49.7963 9.75025 52.6884 6.93182 56.2938 6.93182C59.8992 6.93182 62.7913 9.75219 62.7913 13.4041C62.7913 17.058 59.8973 19.8784 56.2938 19.8784C52.6903 19.8764 49.7963 17.0561 49.7963 13.4041ZM59.1142 13.4041C59.1142 11.6634 57.8872 10.5101 56.2938 10.5101C54.7005 10.5101 53.4735 11.6634 53.4735 13.4041C53.4735 15.1448 54.7005 16.2982 56.2938 16.2982C57.8891 16.2962 59.1142 15.1448 59.1142 13.4041Z"
      fill="#02163D"
    />
    <path
      d="M76.2787 7.27298L71.7428 19.5333H67.5753L63.0394 7.27298H67.1101L69.661 15.046L72.2119 7.27298H76.2787Z"
      fill="#02163D"
    />
    <path
      d="M77.2595 3.98739C77.2595 2.78559 78.2655 1.78149 79.4654 1.78149C80.6653 1.78149 81.6732 2.78559 81.6732 3.98739C81.6732 5.1892 80.6672 6.19523 79.4674 6.19523C78.2675 6.19523 77.2595 5.1892 77.2595 3.98739ZM77.6278 7.27298H81.3069V19.5333H77.6278V7.27298Z"
      fill="#02163D"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="24.4293" y1="12.2193" x2="0.00184037" y2="12.2193" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2369F1" />
        <stop offset="0.999" stopColor="#06C1EF" />
      </linearGradient>
    </defs>
  </svg>
)
