import React from 'react'

export const MoneySign = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 9.08333V11.9167M19 26.0833V28.9167M14.75 26.0833H21.125C22.0643 26.0833 22.9651 25.7102 23.6293 25.046C24.2935 24.3818 24.6667 23.481 24.6667 22.5417C24.6667 21.6024 24.2935 20.7015 23.6293 20.0373C22.9651 19.3731 22.0643 19 21.125 19H16.875C15.9357 19 15.0349 18.6269 14.3707 17.9627C13.7065 17.2985 13.3333 16.3976 13.3333 15.4583C13.3333 14.519 13.7065 13.6182 14.3707 12.954C15.0349 12.2898 15.9357 11.9167 16.875 11.9167H23.25M36 19C36 28.3888 28.3888 36 19 36C9.61116 36 2 28.3888 2 19C2 9.61116 9.61116 2 19 2C28.3888 2 36 9.61116 36 19Z"
      stroke="#2647D7"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
