import React from 'react'

export const Arrow: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 15L13 10M13 10L8 5M13 10H1M12 1H18.0909C18.332 1 18.5632 1.09578 18.7337 1.26627C18.9042 1.43675 19 1.66799 19 1.90909V18.0908C19 18.3319 18.9042 18.5632 18.7337 18.7337C18.5632 18.9042 18.332 18.9999 18.0909 18.9999H12"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
