export const BatteryCharging: React.FC = (props) => (
  <svg width={44} height={44} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Ilustração simplificada de uma bateria carregada.</title>
    <path
      d="M41.25 16.5v11M35.75 31.625v-19.25A2.75 2.75 0 0033 9.625H6.875a2.75 2.75 0 00-2.75 2.75v19.25a2.75 2.75 0 002.75 2.75H33a2.75 2.75 0 002.75-2.75z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.938 28.875L22.688 22h-5.5l2.75-6.875"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
