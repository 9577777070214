export const HealthIcon = () => (
  <svg width="48" height="48" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Gastos</title>
    <desc>Ilustração simplificada de uma cruz médica.</desc>
    <path
      d="M14 26H3.5C3.10218 26 2.72064 25.842 2.43934 25.5607C2.15804 25.2794 2 24.8978 2 24.5V15.5C2 15.1022 2.15804 14.7206 2.43934 14.4393C2.72064 14.158 3.10218 14 3.5 14H14V3.5C14 3.10218 14.158 2.72064 14.4393 2.43934C14.7206 2.15804 15.1022 2 15.5 2H24.5C24.8978 2 25.2794 2.15804 25.5607 2.43934C25.842 2.72064 26 3.10218 26 3.5V14H36.5C36.8978 14 37.2794 14.158 37.5607 14.4393C37.842 14.7206 38 15.1022 38 15.5V24.5C38 24.8978 37.842 25.2794 37.5607 25.5607C37.2794 25.842 36.8978 26 36.5 26H26V36.5C26 36.8978 25.842 37.2794 25.5607 37.5607C25.2794 37.842 24.8978 38 24.5 38H15.5C15.1022 38 14.7206 37.842 14.4393 37.5607C14.158 37.2794 14 36.8978 14 36.5V26Z"
      stroke="#00198A"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
