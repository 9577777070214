import React from 'react'

export const LogoWhite: React.FC = () => (
  <svg width="141" height="42" viewBox="0 0 141 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Provi</title>
    <desc>Logo da Provi</desc>
    <path
      d="M36.8667 7.23838C28.7892 -2.03493 14.7027 -2.398 6.15222 6.14917C2.01854 10.2828 -0.0299789 15.7123 0.00333037 21.1317L0 21.1283V41.9966H20.8683L20.8649 41.9933C26.6874 42.0299 32.5232 39.665 36.7501 34.8884C43.6751 27.0641 43.7284 15.1127 36.8667 7.23838ZM26.6341 26.631C23.523 29.7421 18.48 29.7421 15.3689 26.631C12.2578 23.52 12.2578 18.4769 15.3689 15.3658C18.48 12.2548 23.523 12.2548 26.6341 15.3658C29.7452 18.4769 29.7452 23.52 26.6341 26.631Z"
      fill="white"
    />
    <path
      d="M68.6489 23.0338C68.6489 29.3126 64.0989 34.1591 58.5795 34.1591C55.7549 34.1591 53.6897 33.1898 52.3007 31.5876V41.9968H45.9786V12.5014H52.3007V14.4833C53.6897 12.8811 55.7549 11.9118 58.5795 11.9118C64.0989 11.9085 68.6489 16.755 68.6489 23.0338ZM62.3268 23.0338C62.3268 19.8727 60.2184 17.8942 57.3138 17.8942C54.4059 17.8942 52.3007 19.8761 52.3007 23.0338C52.3007 26.1948 54.4092 28.1734 57.3138 28.1734C60.2217 28.1734 62.3268 26.1915 62.3268 23.0338Z"
      fill="white"
    />
    <path
      d="M84.8705 12.0784V19.2399C82.2591 18.8202 78.5518 19.8727 78.5518 24.0431V33.5662H72.2297V12.498H78.5518V16.2487C79.3912 13.424 82.2158 12.0784 84.8705 12.0784Z"
      fill="white"
    />
    <path
      d="M86.133 23.0338C86.133 16.755 91.1027 11.9118 97.2982 11.9118C103.494 11.9118 108.464 16.7583 108.464 23.0338C108.464 29.3126 103.49 34.1591 97.2982 34.1591C91.106 34.1558 86.133 29.3093 86.133 23.0338ZM102.145 23.0338C102.145 20.0426 100.036 18.0607 97.2982 18.0607C94.5602 18.0607 92.4517 20.0426 92.4517 23.0338C92.4517 26.025 94.5602 28.0069 97.2982 28.0069C100.04 28.0035 102.145 26.025 102.145 23.0338Z"
      fill="white"
    />
    <path
      d="M131.64 12.498L123.846 33.5662H116.684L108.89 12.498H115.885L120.268 25.8551L124.652 12.498H131.64Z"
      fill="white"
    />
    <path
      d="M133.326 6.85212C133.326 4.78695 135.054 3.06152 137.116 3.06152C139.178 3.06152 140.91 4.78695 140.91 6.85212C140.91 8.9173 139.181 10.6461 137.119 10.6461C135.058 10.6461 133.326 8.9173 133.326 6.85212ZM133.958 12.498H140.281V33.5662H133.958V12.498Z"
      fill="white"
    />
  </svg>
)
